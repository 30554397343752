<template>
  <div>
    <h2 class="pageTitle pt-5 mb-8">受付</h2>
    <div class="mx-6" v-if="!checks.started">
      <v-row justify="center">
        <v-btn
          class="mt-12"
          x-large
          color="primary"
          rounded
          @click="checks.started = true"
          >受付を開始する</v-btn
        >
      </v-row>
    </div>
    <div v-else class="mx-12">
      <p class="blue--text text--darken-4">QRコードをかざしてください。</p>
      <v-select
        label="カメラ"
        v-model="camera"
        :items="cameraOptions"
        outlined
      />
      <qrcode-stream
        :camera="camera"
        @decode="onDecode"
        @init="onInit"
      ></qrcode-stream>
    </div>
  </div>
</template>

<script>
import Datastore from "nedb";
import { QrcodeStream } from "vue-qrcode-reader";
import Mixin from "@/assets/js/utils";

export default {
  components: {
    QrcodeStream,
  },
  mixins: [Mixin],
  data: () => ({
    checks: {
      started: false,
      error: "",
    },
    db: {},
    camera: "auto",
    cameraOptions: [
      { text: "自動", value: "auto" },
      { text: "後ろ", value: "rear" },
      { text: "前", value: "front" },
    ],
  }),
  created() {
    this.db = new Datastore({
      filename: "examinerData.db",
    });
    this.db.loadDatabase();
  },
  methods: {
    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        if (error.name === "NotAllowedError") {
          this.error = "ERROR: you need to grant camera access permisson";
        } else if (error.name === "NotFoundError") {
          this.error = "ERROR: no camera on this device";
        } else if (error.name === "NotSupportedError") {
          this.error = "ERROR: secure context required (HTTPS, localhost)";
        } else if (error.name === "NotReadableError") {
          this.error = "ERROR: is the camera already in use?";
        } else if (error.name === "OverconstrainedError") {
          this.error = "ERROR: installed cameras are not suitable";
        } else if (error.name === "StreamApiNotSupportedError") {
          this.error = "ERROR: Stream API is not supported in this browser";
        }
      }
    },
    onDecode(decodedString) {
      const answerData = this.decodeQRcode(decodedString);
      if (answerData.examId && answerData.uid) {
        answerData.created = new Date();
        const update = {
          $push: { data: answerData },
        };
        this.db.update(
          { examId: answerData.examId },
          update,
          { upsert: true },
          () => {
            window.alert("受付が完了しました。");
          }
        );
      } else {
        window.alert("QRコードが間違っています。");
      }
    },
  },
  watch: {
    error(newval) {
      window.alert(newval);
    },
  },
  filters: {},
};
</script>
<style>
.pageTitle {
  border-bottom: solid 3px rgba(0, 0, 0, 0.87);
}
</style>
