// import Vue from "vue";

export default {
  methods: {
    decodeQRcode(qrString, restQuestions = []) {
      const code = qrString.split("?");
      const answerData = {
        examId: code.shift(),
        uid: code.shift(),
      };
      const isEmpty = code.length === 0;
      this.$store.state.defaultQuestions.forEach((dq) => {
        if (isEmpty) {
          answerData[dq.value] = "";
        } else {
          answerData[dq.value] = decodeURIComponent(code.shift().slice(1));
        }
      });
      if (restQuestions.length === 0) {
        return answerData;
      }
      answerData.restAnswers = [];
      for (let i = 0; i < restQuestions.length; i += 1) {
        if (isEmpty) {
          answerData.restAnswers.push({
            text: restQuestions[i].text,

            memo: restQuestions[i].memo,
            tf: 0,
            memoAnswer: "",
          });
        } else {
          const add = {
            text: restQuestions[i].text,
            memo: restQuestions[i].memo,
            tf: Number(code[i].slice(0, 1)),
            memoAnswer: decodeURIComponent(code[i].slice(1)),
          };
          answerData.restAnswers.push(add);
        }
      }
      if (isEmpty) {
        answerData.empty = true;
      }
      return answerData;
    },
  },
};
